body {
  background: url('../assets/images/bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
}

a {
  text-decoration: none;
  background-color: transparent;
}

p {
  margin: 0;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol, ul {
  list-style: none;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

.none {
  display: none !important;
}

.block {
  display: block;
}

.column {
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 1rem;
}

.nopadding {
  padding: 0 !important;
}

.label {
  font-size: 0.875rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.text-right {
  text-align: right;
}

.bet-type-group {
  margin: 1rem;
}

.bet-type-group .btn {
  margin-right: 16px;
  box-shadow: 1px 3px 3px 0px rgb(0 0 0 / 16%);
  background: #ffffff;
  color: #073880;
  border: 0;
  border-radius: 2px !important;
  font-weight: 300 !important;
  min-width: 100px;
}

.bet-type-group .btn.active {
  background: #073880;
  color: #ffffff;
}


.bet-choose-group {
  margin: 1rem;
}

.bet-choose-group .btn {
  background: linear-gradient(180deg, #ffffff 0%, #91a4c1 100%);
  font-family: UTM Avo;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #073880;
  border: solid 1px #707070;
  border-radius: 0;
}

.bet-choose-group .btn.active {
  background: #ffffff;
}

.number-buttons {
  margin: 8px 0;
}

.number-buttons .btn {
  margin: 0 8px;
  height: 40px;
  width: 65px;
  border-radius: 4px !important;
  background: #fff;
  color: #073880;
  padding: 0;
}

.number-buttons .btn.active {
  background: #f9ca67;
  border: 0;
}

.type-buttons {
  margin: 4px 0;
}

.type-buttons .btn {
  margin: 0 8px;
  height: 40px;
  width: 80px;
  border-radius: 4px !important;
  background: #f9ca67;
  color: #073880;
  padding: 0 4px;
  font-size: 14px;
  border: 0;
}

.type-buttons .btn:hover {
  background: #ffffff;
}

.type-buttons .btn.active {
  background: #ffffff;
}

.input-multiple {
  background: #366fc2;
  width: 120px;
  height: 32px;
  padding: 2px;
  border-radius: 4px;
}

.input-multiple button {
  background-color: #ffffff;
  color: #366fc2;
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 4px !important;
  padding: 0;
  font-size: 22px;
  line-height: 24px;
  margin: 2px;
  font-weight: 200;
}

.input-multiple input {
  text-align: center;
  background: transparent;
  border: 0;
  color: #ffffff;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
}

.input-multiple input:focus,
.input-multiple input:active {
  border: 0;
  background: transparent;
  color: #ffffff;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
}

.btn .badge {
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
}

.btn.lg {
  line-height: 32px !important;
  padding: 8px 32px;
}

.btn-primary {
  background: linear-gradient(0deg, #0452c4 0%, #2d87f1 85.7%);
  box-shadow: 2px 3px 6px 0px #00000029;
  color: #ffffff;
  font-family: UTM Avo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
}

.btn-warning {
  background: linear-gradient(0deg, #ffbf1e 0%, #eecb9e 85.7%);
  box-shadow: 2px 3px 6px 0px #00000029;
  color: #073880;
  font-family: UTM Avo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
}

.btn-danger {
  background: linear-gradient(0deg, #e84747 0%, #fe6141 100%);
  color: #ffffff;
  border: 0;
}

.btn-bet-now,
.btn-add-ticket {
  padding: 0 12px;
  min-width: 150px;
  height: 45px;
  line-height: 43px;
}

.nav-link {
  color: #073880;
}

.nav-tabs.play-tabs {
  background: #073880;
  border: 0;
}

.nav-tabs.play-tabs .nav-item {
  width: 138px;
  height: 45px;
  border-right: solid 1px #e6eeff;
}

.nav-tabs.play-tabs .nav-link {
  border-right: 0;
  color: #fff;
  margin: 0 !important;
  text-align: center;
  width: 100%;
  font-family: UTM Avo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 45px;
  padding: 0;
  border: 0;
  border-radius: 0;
}

.nav-tabs.play-tabs .nav-link:hover {
  border: 0;
  background-color: rgba(7, 56, 128, 0.5);
}

.nav-tabs.play-tabs .nav-link.active {
  background-color: #c0cade;
  line-height: 45px;
  color: #073880;
}

.table {
  --bs-table-bg: rgba(0, 0, 0, 0.05);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: transparent;
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.075);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 0;
  color: #073880;
  vertical-align: top;
  border-color: #bed2ee;
}

.table>thead {
  font-family: UTM Avo;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #073880;
}

.table th {
  font-weight: 400 !important;
  text-align: center;
  background: #ffff;
}

.table td {
  text-align: center;
  font-size: 14px;
}

.table > :not(:first-child) {
  border-top: 0;
}

.table.sticky-header thead tr th {
   position: sticky;
   z-index: 1;
}

.table.sticky-header thead tr:first-child th {
  top: 0;
}

.icon-del {
  padding: 0;
  height: 24px;
  width: 24px;
}

.icon-del > svg {
  margin-top: -8px;
  margin-left: -1px;
}

.icon-trash2 {
  color: #ffffff;
  text-decoration: none;
  padding: 2px 12px;
}

.icon-trash2 > svg:last-child {
  margin-right: 4px;
}

.bet-details th.numbers {
  max-width: 300px;
}

.bet-details .num-separator {
  display: inline-block;
  vertical-align: middle;
  margin: 0 4px;
  width: 2px;
  height: 12px;
  background-color: #DEA65E;
  position: relative;
  top: -1px;
}

.bet-details-buttons .btn {
  border-radius: 4px !important;
  border: 0;
  height: 45px;
  padding: 0 16px;
}

.bet-details-buttons .btn:not(:last-child) {
  margin-right: 16px;
}

.bet-details-buttons .bet-agains {
  background: linear-gradient(0deg, #ffbf1e 0%, #eecb9e 85.7%);
  box-shadow: 2px 3px 6px 0px #00000029;
  font-family: UTM Avo;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #073880;
}

.bet-details-buttons .bet-agains>svg {
  margin-right: 4px;
}

.bet-details-buttons .bet {
  background: linear-gradient(0deg, #0452c4 0%, #2d87f1 85.7%);
  box-shadow: 2px 3px 6px 0px #00000029;
  font-family: UTM Avo;
  font-style: normal;
}

.type-panel-actions {
  padding: 0 1rem;
}

.type-panel-actions .type-buttons .btn {
  border-radius: 0 !important;
  margin: 0 0 0 5px !important;
  width: 75px;
}

.type-panel-actions .type-buttons .btn:first-child {
  margin: 0 0 0 10px !important;
}

.type-panel-actions .col,
.type-panel-actions .col-sm-3,
.type-panel-actions .col-sm-9 {
  padding: 0 !important;
}

.type-panel-actions .row {
  margin-bottom: 0.5rem;
}

.type-panel-actions .row:last-child {
  margin-top: 1rem;
  gap: 0.5rem;
}

.type-panel-actions .row:last-child > .btn {
  display: flex;
  width: auto;
  min-width: 120px;
  justify-content: center;
}

.type-panel-actions .row:last-child .col .btn {
  margin-right: 0.5rem;
  height: 40px;
  width: 125px;
  padding: 0;
  line-height: 40px;
}

.type-panel-actions .row:last-child .col .btn >svg {
  margin-top: -4px;
  margin-right: 4px;
}

.fast-choose-panel .btn {
  height: 40px;
  border: 0;
}

.fast-choose-panel .guide {
  text-align: right;
  padding-right: 1rem;
}

.fast-choose-panel .guide .btn {
  width: 80px;
}

.fast-choose-panel .number-ranges .btn {
  margin: 0 4px;
  height: 40px;
  width: 85px;
  border-radius: 0 !important;
  font-size: 14px;
  font-weight: 700;
  padding: 0 8px;
}

.fast-choose-panel .number-ranges .btn:first-child {
  margin-left: 4px;
}

.fast-choose-panel .number-ranges .btn-primary {
  background: #366fc2;
  color: #ffffff;
}

.fast-choose-panel .number-ranges .btn-light {
  color: #073880;
  font-weight: 400;
}

.fast-choose-panel .type-panel-actions .type-buttons .btn {
  border-radius: 0 !important;
  margin: 0 0 0 5px !important;
  width: 75px;
}
  
.fast-choose-panel .type-panel-actions .type-buttons .btn:first-child {
  margin-left: 0 !important;
}

.feeds th.fixed-width {
  width: 200px;
}

.feeds td {
  line-height: 32px;
}

.feeds td.select > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.feeds td.multiple-input {
  display: flex;
  justify-content: center;
}


@media (max-width: 960px) {
  .sm {
    display: none !important;
  }

  .bet-type-group .btn {
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }

  .type-panel-actions .row {
    margin-bottom: 0.5rem;
  }

  .type-panel-actions .row:last-child {
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap: 0.5rem;
  }

  .type-panel-actions .row:last-child > .btn {
    width: 100%;
    min-width: auto;
  }
}

/* Bootstrap */
.dropdown-menu {
  max-height: 350px;
  overflow-y: auto;
}

.react-datepicker-wrapper input {
  border: 1px solid #dedeea;
  border-radius: 12px;
  width: 100%;
  height: 48px;
  padding: 0 1rem;
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 700;
}

.react-datepicker {
  font-size: 0.875rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #dedeea;
  border-radius: 12px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #dedeea;
}

.react-datepicker .react-datepicker__month-container {
  min-width: 320px;
}

.react-datepicker__header {
  border-bottom: 1px solid #dedeea;
  border-top-left-radius: 12px;
}

.date-picker {
  margin: 0;
}

#tool-control {
  position: relative;
  z-index: 1001;
  display: flex;
  justify-content: center;
  width: 100%;
  top: -56px;
  gap: 0.25rem;
}

#tool-control > div {
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background-size: 30px 30px;
  background-position: 4px 4px;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px 0 #e4e7ef;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

#tool-control > div:hover {
  border-color: #789cff;
}

#tool-control > div.active {
  border-color: #d5dff2;
  background-color: #d5dff2;
}

#marker {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker_editor.png');
}

#polyline {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/polyline.png');
}

#polygon {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/polygon.png');
}

#circle {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/circle.png');
}

#rectangle {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/rectangle.png');
}

#ellipse {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/ellipse.png');
}